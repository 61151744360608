import { FlexBox } from '@codecademy/gamut';
import { Loading } from '@mono/brand';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';

type ConditionallyRedirectedProps =
  | {
      children: ReactElement;
      renderChildren?: never;
    }
  | {
      renderChildren: () => ReactElement;
      children?: never;
    };

export const ConditionallyRedirected: React.FC<
  ConditionallyRedirectedProps
> = ({ children, renderChildren }) => {
  return useRouter().isFallback ? (
    <FlexBox height="100vh" alignItems="center" justifyContent="center">
      <Loading />
    </FlexBox>
  ) : (
    children || renderChildren?.() || null
  );
};
