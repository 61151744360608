import { PageBusinessPlansDataFragment } from '@mono/data/codegen/contentful-landing-page';

import {
  BusinessPlan,
  isBusinessPlan,
  isPlanFeature,
  PlanFeature,
} from '../types';

export const mapPlansPropToBusinessPlan = (
  plans: PageBusinessPlansDataFragment['plans']
): BusinessPlan[] => (Array.isArray(plans) ? plans.filter(isBusinessPlan) : []);

export const mapFeaturesPropToPlanFeature = (
  features: PageBusinessPlansDataFragment['features']
): PlanFeature[] =>
  Array.isArray(features) ? features.filter(isPlanFeature) : [];
