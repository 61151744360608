import { Anchor /* FillButton */ } from '@codecademy/gamut';
// import { css, SerializedStyles, Theme } from '@emotion/react';
import styled from '@emotion/styled';

// const verticalSpacing = ({ theme }: { theme: Theme }): SerializedStyles => css`
//   margin-top: 1rem;
//   ${theme.breakpoints.sm} {
//     margin-top: 1.5rem;
//   }
// `;

export const StyledHeader = styled.h1`
  margin: 0;

  color: currentColor;
  font-size: ${({ theme }) => theme.fontSize[26]};
  line-height: 1.29;

  ${({ theme }) => theme.breakpoints.sm} {
    font-size: ${({ theme }) => theme.fontSize[44]};
  }
`;

export const StyledParagraph = styled.p`
  margin-top: 1rem;
  margin-bottom: 0;

  font-size: ${({ theme }) => theme.fontSize[18]};
  line-height: 1.625;
`;

export const StyledEmail = styled.span`
  color: ${({ theme }) => theme.colors[`hyper-400`]};
`;

export const StyledAnchor = styled(Anchor)`
  display: inline-flex;
  align-items: center;
`;

// export const StyledFillButton = styled(FillButton)`
//   ${verticalSpacing}
// `;

export const StyledSmallText = styled.p`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSize[14]};
  line-height: 1.7;
`;

export const StyledHelpDetails = styled(StyledSmallText)`
  margin-top: 0.5rem;
  margin-bottom: 0;

  font-size: ${({ theme }) => theme.fontSize[14]};
  line-height: 1.7;
`;

// export const StyledResent = styled(StyledSmallText)<{ hasErrored?: boolean }>`
//   color: ${({ hasErrored, theme }) =>
//     hasErrored ? theme.colors.red : theme.colors.white};
//   height: 1rem;
//   margin: 0.5rem 0 0;
// `;
