/* eslint-disable local-rules/a11y-prefer-template-literal-over-jsx-expression */

import { Anchor, Box, GridForm, Text } from '@codecademy/gamut';
import { ColorMode } from '@codecademy/gamut-styles';
import {
  GuardedCaptchaProvider,
  sendCaptchaGuardedRequest,
} from '@mono/ui/captcha';
import React, { useState } from 'react';

import { trackEvent } from '~/libs/tracking';

import { FORM_FIELDS, onTrialInvitationSubmit } from './helpers';
import { FormDetails, FormHeader, StyledFormError } from './styles';
import { FormEntriesType, TrialFormValues } from './types';

export const trackFormSubmission = (
  error: boolean,
  email: string,
  plan_name: string
) =>
  trackEvent('form', 'submit', {
    error,
    email,
    plan_name,
    form: 'business_trial_sign_up',
  });

const ErrorMessage = ({ error }: { error: string }) => {
  const hasError = Boolean(error);

  const errorMessage =
    error === 'An account already exists.' ? (
      <>
        An account with that email already exists.
        <br />
        You can login <Anchor href="/login">here</Anchor>.
      </>
    ) : (
      error
    );

  return (
    <StyledFormError aria-live="polite">
      {hasError && errorMessage}
    </StyledFormError>
  );
};

export const BusinessTrialForm = ({
  onSuccess,
}: {
  onSuccess: (email: string, company: string, invitationId?: string) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [captcha, setCaptcha] = useState<React.ReactNode>();

  const onSubmitForm = async (values: TrialFormValues) => {
    const resetFormAndCaptcha = () => {
      setCaptcha(undefined);
      setIsLoading(false);
    };

    try {
      const { company, email, source, firstname, lastname, phoneNumber } =
        values;
      setIsLoading(true);

      const [captcha, promise] = sendCaptchaGuardedRequest({
        action: 'create_business_trial',
        requestV2: (captchaToken) =>
          onTrialInvitationSubmit({
            recaptchaVersion: 'v2',
            recaptchaToken: captchaToken,
            company,
            email,
            source,
            firstname,
            lastname,
            phoneNumber,
          }),
        requestV3: (captchaToken) =>
          onTrialInvitationSubmit({
            recaptchaVersion: 'v3',
            recaptchaToken: captchaToken,
            company,
            email,
            source,
            firstname,
            lastname,
            phoneNumber,
          }),
        reCaptchaKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
      });

      setCaptcha(captcha);

      const response = await promise;

      if (response.status === 'complete') {
        const { errors, invitationId } = response.result;

        if (errors) {
          // error in the response
          setIsLoading(false);
          trackFormSubmission(true, email, company);
          setSubmitError(`${errors[0].description}`);
        } else {
          trackFormSubmission(false, email, company);
          onSuccess(email, company, invitationId);
        }
      } else {
        // status is not complete
        // captcha status is failed or incomplete
        setIsLoading(false);
        setSubmitError('reCAPTCHA failed');
      }
    } catch (error) {
      setIsLoading(false);
      setSubmitError('An error has occurred.');
    }
    resetFormAndCaptcha();
  };

  return (
    <GuardedCaptchaProvider hideBadge={false}>
      <Box width={{ _: '100%', sm: 'unset' }}>
        <FormHeader>Start your team&rsquo;s free trial</FormHeader>
        <FormDetails>
          Invite up to 10 members of your team to join a free two-week trial.
        </FormDetails>
        <ColorMode mode="dark">
          <GridForm
            columnGap={{
              xs: 8,
              xl: 8,
            }}
            fields={Object.entries(FORM_FIELDS).map(
              ([
                key,
                { validate, customError, type, label },
              ]: FormEntriesType) => ({
                disabled: isLoading,
                id: key,
                label,
                name: key,
                size: { _: 12, sm: 6 },
                type,
                validation: {
                  validate: (value) => validate?.(value) || customError,
                  required: customError,
                },
                layout: 'left',
                color: 'white',
              })
            )}
            onSubmit={onSubmitForm}
            validation="onSubmit"
            submit={{
              contents: 'Start free trial',
              type: 'fill',
              size: 12,
              disabled: isLoading,
            }}
          />
          <Text as="small" fontSize={14}>
            By signing up for Codecademy, you agree to Codecademy&apos;s{' '}
            <Anchor target="_blank" href="/terms" variant="standard">
              Terms of Service
            </Anchor>{' '}
            &amp;{' '}
            <Anchor target="_blank" href="/policy" variant="standard">
              Privacy Policy
            </Anchor>
            .
          </Text>
        </ColorMode>
        <ErrorMessage error={submitError} />
        {captcha}
      </Box>
    </GuardedCaptchaProvider>
  );
};
