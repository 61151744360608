import { Box, Column, LayoutGrid, Text } from '@codecademy/gamut';
import { theme } from '@codecademy/gamut-styles';
import { scrollToTarget } from '@mono/util/dom';
import router from 'next/router';
import React, { useEffect, useRef } from 'react';

import {
  BusinessPlan,
  businessSectionPricingTableAnchor,
  PlanFeature,
} from '../types';
import {
  mapFeaturesPropToPlanFeature,
  mapPlansPropToBusinessPlan,
} from './helpers';
import { PricingCard } from './PricingCard';
import { PricingTableContent } from './PricingTableContent';

export type PricingTableLegacyProps = {
  title: string | null;
  plans: BusinessPlan[];
  features: PlanFeature[];
  testId: string;
};

export const PricingTableLegacy: React.FC<PricingTableLegacyProps> = ({
  title,
  testId,
  ...props
}) => {
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      router?.asPath?.includes(`#${businessSectionPricingTableAnchor}`) &&
      tableRef.current
    ) {
      scrollToTarget(tableRef.current);
    }
  });

  const plans = mapPlansPropToBusinessPlan(props.plans);
  const features = mapFeaturesPropToPlanFeature(props.features);

  return (
    <div data-testid={testId} ref={tableRef} id="pricing">
      <Box py={64}>
        <LayoutGrid gap={32} pb={64}>
          <Column size={{ _: 12, md: 3 }}>
            {title && (
              <Text
                as="h2"
                variant={theme.breakpoints.sm ? 'title-lg' : 'title-xl'}
                fontSize={44}
              >
                {title}
              </Text>
            )}
          </Column>
          <Column size={1} />
          {plans.map((plan) => (
            <Column
              key={plan.planId}
              gridColumnEnd="span 12"
              size={{ _: 12, md: 4 }}
            >
              <PricingCard {...plan} />
            </Column>
          ))}
        </LayoutGrid>
        <PricingTableContent plans={plans} features={features} />
      </Box>
    </div>
  );
};
