import { Box, Column, LayoutGrid } from '@codecademy/gamut';
import {
  Description,
  FeaturedImage,
  PageSingleFeatureProps,
  Title,
  TitleWithEyebrow,
} from '@mono/brand';
import * as React from 'react';

import { BusinessTrialSignUp } from './BusinessPageHero/BusinessTrialSignup';

export const PageBusinessFormSingleFeature: React.FC<
  PageSingleFeatureProps
> = ({
  eyebrow,
  desc,
  hideImageOnMobile,
  isPageHeading,
  media,
  onAnchorClick,
  testId,
  title,
}) => {
  const primaryContent = (
    <>
      {title &&
        (eyebrow ? (
          <TitleWithEyebrow
            title={title}
            eyebrow={eyebrow}
            isPageHeading={isPageHeading}
          />
        ) : (
          <Title isPageHeading={isPageHeading}>{title}</Title>
        ))}
      {desc && <Description text={desc} onAnchorClick={onAnchorClick} />}
    </>
  );

  return (
    <LayoutGrid data-testid={testId} rowGap={16} columnGap={{ _: 32 }}>
      <Column
        size={{ sm: 6 }}
        alignContent="flex-start"
        rowGap={{ _: 0, sm: 24 }}
      >
        {primaryContent}
        {media?.type === 'image' && (
          <FeaturedImage {...media} hideImageOnMobile={hideImageOnMobile} />
        )}
      </Column>
      <Column
        size={{ sm: 6 }}
        alignContent="flex-start"
        display="flex"
        justifyContent={{ _: 'flex-start', sm: 'flex-end' }}
      >
        <Box flex={{ _: 1, sm: 'unset' }}>
          <BusinessTrialSignUp />
        </Box>
      </Column>
    </LayoutGrid>
  );
};
