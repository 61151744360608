import {
  Badge,
  FillButton,
  FlexBox,
  StrokeButton,
  Text,
  TextButton,
} from '@codecademy/gamut';
import { Background, BackgroundProps, theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

import { planData } from './consts';

// this gets the border to show up on the sticky header without setting border-collapse to separate on the table
const BackgroundWithBorder = styled(Background)`
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: -1px;
    width: 100%;
    border-top: 1px solid ${theme.colors.navy};
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.navy};
  }
`;

export const PlanCard: React.FC<
  Partial<BackgroundProps> & {
    plan: (typeof planData)[number];
  }
> = ({ plan, ...backgroundProps }) => (
  <BackgroundWithBorder
    p={24}
    border={1}
    borderColor="navy"
    bg={plan.bg as BackgroundProps['bg']}
    {...backgroundProps}
  >
    <FlexBox column alignItems="center">
      <Text variant="title-md">{plan.title}</Text>
      <Badge variant="tertiary" my={16}>
        {plan.badge}
      </Badge>
      <Text fontWeight="normal" textAlign="center" mb={16}>
        {plan.description}
      </Text>
      <FlexBox alignItems="center" gap={4}>
        {plan.price && (
          <>
            <Text variant="title-lg">$</Text>
            <Text variant="title-xxl">{plan.price}</Text>
            <Text variant="p-base" color="text-secondary" textAlign="center">
              {plan.paymentDetail}
            </Text>
          </>
        )}
      </FlexBox>
      <FlexBox column mt={24} gap={12} alignItems="center">
        {plan.ctas.map((cta) => {
          switch (cta.variant) {
            case 'stroke':
              return (
                <StrokeButton
                  key={cta.label}
                  href={cta.href}
                  onClick={cta.onClick}
                >
                  {cta.label}
                </StrokeButton>
              );
            case 'fill':
              return (
                <FillButton
                  key={cta.label}
                  href={cta.href}
                  onClick={cta.onClick}
                >
                  {cta.label}
                </FillButton>
              );
            case 'text':
              return (
                <TextButton
                  key={cta.label}
                  href={cta.href}
                  onClick={cta.onClick}
                >
                  {cta.label}
                </TextButton>
              );
            default:
              return null;
          }
        })}
      </FlexBox>
    </FlexBox>
  </BackgroundWithBorder>
);
