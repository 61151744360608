/* eslint-disable local-rules/a11y-prefer-template-literal-over-jsx-expression */

import {
  Box,
  Column,
  FlexBox,
  HiddenText,
  InfoTip,
  LayoutGrid,
  Text,
} from '@codecademy/gamut';
import { CheckIcon } from '@codecademy/gamut-icons';
import { css, pxRem, theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { ContentMarkdown } from '@mono/ui/components';
import React from 'react';

import { BusinessPlan, PlanFeature } from '../types';

const FeatureRow = styled(LayoutGrid)(
  css({
    minHeight: `${pxRem(48)}`,
    alignItems: 'center',
    '&:nth-of-type(even)': {
      background: `${theme.colors.white}`,
    },
  })
);

export type PricingTableContentProps = {
  plans: BusinessPlan[];
  features: PlanFeature[];
};

export const PricingTableContent: React.FC<PricingTableContentProps> = ({
  plans,
  features,
}) => {
  return (
    <Box as="table" width="100%">
      <Box as="thead" display={{ md: 'none' }}>
        <LayoutGrid as="tr" gap={32} pb={24}>
          <Column size={{ _: 8, md: 4 }} verticalAlign="center" as="th">
            <Text variant="title-sm" pl={{ _: 16, md: 24 }}>
              Features
            </Text>
          </Column>
          {plans.map((plan) => (
            <Column key={plan.planId} size={{ _: 2, md: 4 }} as="th">
              <Text
                variant="title-sm"
                pl={{ _: 4, md: 24 }}
                textAlign="center"
                truncate="ellipsis"
                truncateLines={1}
              >
                {plan.title}
              </Text>
            </Column>
          ))}
        </LayoutGrid>
      </Box>
      <Box as="tbody">
        {features.map(({ heading, description, items }) => (
          <FeatureRow as="tr" key={heading} gap={32}>
            <Column size={{ _: 8, md: 4 }} verticalAlign="center" as="td">
              <Box pl={{ _: 16, md: 24 }} pr={24}>
                <Text variant="p-large" pr={8}>
                  {heading}
                </Text>
                {description && (
                  <InfoTip
                    info={<ContentMarkdown text={description} />}
                    alignment="bottom-left"
                    placement="floating"
                  />
                )}
              </Box>
            </Column>
            {items.map((item, index) => (
              <Column
                size={{ _: 2, md: 4 }}
                key={`${heading}-${plans[index].planId}`}
                as="td"
              >
                <Box verticalAlign="middle">
                  <FlexBox justifyContent={{ _: 'center', md: 'normal' }}>
                    {item.includeCheckmark && (
                      <Box>
                        <CheckIcon
                          color={plans[index].isRecommended ? 'hyper' : 'navy'}
                          height={17}
                          width={24}
                          verticalAlign="middle"
                        />
                      </Box>
                    )}
                    <HiddenText>
                      {plans[index].planId} plan, {heading},
                      {item.includeCheckmark || item.title ? '' : 'not '}
                      included
                    </HiddenText>
                    {item.title && (
                      <Text
                        variant="p-large"
                        display={{ _: 'none', md: 'initial' }}
                        ml={24}
                      >
                        <ContentMarkdown text={item.title} />
                      </Text>
                    )}
                  </FlexBox>
                </Box>
              </Column>
            ))}
          </FeatureRow>
        ))}
      </Box>
    </Box>
  );
};
