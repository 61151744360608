// This file contains helpers that reference GraphQL Maybe<any> which is the
// generated type for a JSON Blob field.  These helpers are designed to translate
// Them into proper types, but need to reference any as the inputs.
import { PageBusinessPlansDataFragment } from '@mono/data/codegen/contentful-landing-page';

export type BusinessPlanID = 'teams' | 'enterprise';

export type BusinessPlan = {
  planId: BusinessPlanID;
  title: string;
  description: string;
  cta: {
    href: string;
    title: string;
  };
  isRecommended: boolean;
};

export type PlanFeature = {
  heading: string;
  description: string;
  items: {
    includeCheckmark: boolean;
    title: string;
  }[];
};

export const isBusinessPlan = (
  p: PageBusinessPlansDataFragment['plans'][number]
): p is BusinessPlan =>
  !!(p?.planId && p?.title && p?.cta && p?.cta?.href && p?.cta?.title);

export const isPlanFeature = (
  f: PageBusinessPlansDataFragment['features'][number]
): f is PlanFeature => !!(f?.heading && f?.items);

export const businessSectionPricingTableAnchor = 'section-pricing-table';
