import React from 'react';

import { ConditionallyRedirected } from '~/components/ConditionallyRedirected';
import { mapBackgroundColor } from '~/components/LandingPage/helpers';
import { LandingPage } from '~/components/LandingPage/LandingPage';
import { LandingPageRouteProps } from '~/components/LandingPage/types';
import { PortalPage } from '~/components/PortalPage';

export const LandingPageRoute: React.FC<LandingPageRouteProps> = ({
  seo,
  backgroundColor,
  slug,
  children,
  finalizedSectionsList,
}) => {
  return (
    <ConditionallyRedirected
      renderChildren={() => (
        <PortalPage
          meta={{
            title: seo.title,
            description: seo.description,
            noIndex: !seo.isIndexed,
            image: seo.image.url,
          }}
          backgroundColor={mapBackgroundColor[backgroundColor]}
          tracking={{
            page_name: `page_${slug}`,
          }}
        >
          <LandingPage items={finalizedSectionsList} slug={slug}>
            {children}
          </LandingPage>
        </PortalPage>
      )}
    />
  );
};
