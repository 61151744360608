import { ContentContainer, FlexBox, GridBox, Text } from '@codecademy/gamut';
import { Background } from '@codecademy/gamut-styles';

import { ReactComponent as AirbnbLogo } from './Airbnb.svg';
import { ReactComponent as AmazonLogo } from './Amazon.svg';
import { ReactComponent as DailymotionLogo } from './Dailymotion.svg';
import { ReactComponent as ReverbLogo } from './Reverb.svg';

const logos = [
  {
    Logo: AirbnbLogo,
    name: 'airbnb',
    height: 37,
    offset: 0,
  },
  {
    Logo: AmazonLogo,
    name: 'amazon',
    height: 28,
    offset: 7,
  },
  {
    Logo: DailymotionLogo,
    name: 'dailymotion',
    height: 26,
    offset: 0,
  },
  {
    Logo: ReverbLogo,
    name: 'reverb',
    height: 28,
    offset: -5,
  },
];

export const LogoWall = () => (
  <Background bg="hyper" py={24}>
    <ContentContainer>
      <FlexBox
        gap={{ _: 16, md: 64 }}
        alignItems={{ _: 'center', sm: 'flex-start', md: 'center' }}
        flexDirection={{ _: 'column', md: 'row' }}
      >
        <Text variant="p-large" fontWeight={700} whiteSpace="nowrap">
          Trusted by teams of all sizes
        </Text>
        <GridBox
          as="ul"
          listStyleType="none"
          flex={1}
          p={0}
          m={{ _: 'auto', sm: 0 }}
          width={{ _: '100%', md: 'auto' }}
          gap={{ _: 32, md: 40, lg: 64 }}
          justifyContent={{
            _: 'center',
            sm: 'space-between',
            md: 'flex-start',
          }}
          gridTemplateColumns={{
            _: 'repeat(2, auto)',
            sm: 'repeat(4, auto)',
          }}
        >
          {logos.map(({ Logo, name, height, offset }) => (
            <FlexBox
              key={name}
              as="li"
              alignItems="center"
              justifyItems="center"
              position="relative"
              top={offset}
            >
              <Logo key={name} aria-label={name} height={height} width="auto" />
            </FlexBox>
          ))}
        </GridBox>
      </FlexBox>
    </ContentContainer>
  </Background>
);
