import { Box, Text } from '@codecademy/gamut';
import { CheckCircledIcon } from '@codecademy/gamut-icons';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

import { planData, tableData } from './consts';
import { FeatureTitle } from './FeatureTitle';
import { PlanCard } from './PlanCard';

const Table = styled(Box)`
  table-layout: fixed;
`.withComponent('table');

const Row = styled(Box)`
  &:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.colors['navy-100']};
  }
`.withComponent('tr');

const Cell = styled(Box)`
  &:not(:first-of-type) {
    border-left: 1px solid ${({ theme }) => theme.colors.navy};
  }
`.withComponent('td');

export const PricingTableDesktop: React.FC = () => (
  <Table width="100%" position="relative" cellPadding={0} cellSpacing={0}>
    <thead>
      <tr>
        <Box as="th" verticalAlign="top">
          <Text variant="title-lg">Compare plans</Text>
        </Box>
        {planData.map((plan) => (
          <PlanCard
            key={plan.id}
            plan={plan}
            as="th"
            position="sticky"
            top={theme.elements.headerHeight}
            verticalAlign="top"
          />
        ))}
      </tr>
    </thead>
    <Box as="tbody" bg="white" border={1}>
      {tableData.map((row) => (
        <Row key={row.title}>
          <Cell py={16} px={24}>
            <FeatureTitle title={row.title} description={row.description} />
          </Cell>
          {planData.map((plan) => (
            <Cell key={plan.id} p={16} textAlign="center">
              {row.plans.includes(plan.id) ? (
                <CheckCircledIcon
                  size={24}
                  verticalAlign="middle"
                  aria-hidden={false}
                  aria-label="yes"
                />
              ) : (
                <Text aria-label="no">-</Text>
              )}
            </Cell>
          ))}
        </Row>
      ))}
    </Box>
  </Table>
);
