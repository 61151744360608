import { FlexBox } from '@codecademy/gamut';
import React, { useState } from 'react';

import { BusinessTrialForm } from '../Form';
import { VerifyForm } from '../Verify';

export interface WindowWithMetadata {
  Metadata: {
    pixel: {
      sendData: (args: { email: string; company: string }) => void;
    };
  };
}

export const BusinessTrialSignUp: React.FC = () => {
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [userData, setUserData] = useState<{
    email: string;
    invitationId?: string;
  }>({
    email: '',
    invitationId: '',
  });

  const onSuccess = (email: string, company: string, invitationId?: string) => {
    setUserData({ email, invitationId });
    setSubmissionSuccess(true);
    if ((window as unknown as WindowWithMetadata).Metadata) {
      (window as unknown as WindowWithMetadata).Metadata.pixel.sendData({
        email,
        company,
      });
    }
  };

  return (
    <FlexBox>
      {!submissionSuccess && <BusinessTrialForm onSuccess={onSuccess} />}
      {submissionSuccess && (
        <VerifyForm
          email={userData.email}
          invitationId={userData.invitationId}
        />
      )}
    </FlexBox>
  );
};
