let loadedStyles = false;
let loadedScript = false;
let loadedMetrics = false;

export const initializeSheerId = () => {
  if (loadedScript && loadedStyles && loadedMetrics) {
    return Promise.resolve([window.sheerId]);
  }

  const scriptPromise = new Promise((resolve) => {
    const script = document.createElement('script');
    script.onload = () => {
      loadedScript = true;
      resolve(window.sheerId);
    };
    script.src =
      'https://cdn.jsdelivr.net/npm/@sheerid/jslib@1.163.0/sheerid.js';
    script.integrity = 'sha256-2EZ5Tjy3633MLsNuNVZtwmzA2GFN/yVukdXxgVCVeus=';
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);
  });

  const stylesPromise = new Promise<void>((resolve) => {
    const styles = document.createElement('link');
    styles.onload = () => {
      loadedStyles = true;
      resolve();
    };
    styles.rel = 'stylesheet';
    styles.type = 'text/css';
    styles.href =
      'https://cdn.jsdelivr.net/npm/@sheerid/jslib@1.163.0/sheerid.css';
    styles.integrity = 'sha256-t5MeFTNyzkPrroKWtcYjxzW3eeK1QMUbQntLkEjJod4=';
    styles.crossOrigin = 'anonymous';
    document.head.appendChild(styles);
  });

  const metricsPromise = new Promise<void>((resolve) => {
    const metrics = document.createElement('script');
    metrics.onload = () => {
      loadedMetrics = true;
      resolve();
    };
    metrics.src =
      'https://cdn.jsdelivr.net/npm/@sheerid/jslib@1.163.0/metrics.js';
    metrics.integrity = 'sha256-Na2XZ6OVrgkkQV1V0WDz1s0l53xybSHnUxsmXXoi5tk=';
    metrics.crossOrigin = 'anonymous';
    document.head.appendChild(metrics);
  });

  return Promise.all([scriptPromise, stylesPromise, metricsPromise]);
};

export const triggerSheerIdModal = async (programId: string) => {
  const [sheerId] = await initializeSheerId();
  sheerId.setOptions({ logLevel: 'info' });
  sheerId.loadInModal(`https://services.sheerid.com/verify/${programId}/`);
};
