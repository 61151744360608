/* eslint-disable local-rules/a11y-prefer-template-literal-over-jsx-expression */

import { Anchor, Box } from '@codecademy/gamut';
import React from 'react';

// import { trackUserClick } from '../../../../../libs/tracking';
// import { resendTrialPlanInvitation } from './helpers';
import {
  StyledAnchor,
  StyledEmail,
  // StyledFillButton,
  StyledHeader,
  StyledHelpDetails,
  StyledParagraph,
  // StyledResent,
} from './styles';

/*
Commented-out code is for hiding the "resend"-related button & text since the resend email logic is not working.
This was noticed on 08/28/2023
Will be uncommented once the resend email logic is fixed.
*/

export const VerifyForm = ({
  email,
  invitationId,
}: {
  email: string;
  invitationId?: string;
}) => {
  // const [buttonPressed, setButtonPressed] = useState(false);
  // const [hasSucceeded, setHasSucceeded] = useState(false);
  // const [isResendRequestInProgress, setIsResendRequestInProgress] =
  //   useState(false);

  // const resendEmail = async () => {
  //   trackUserClick({
  //     target: 'resend_verification_email',
  //     context: 'business_trial_sign_up',
  //     page_name: 'business',
  //   });

  //   setButtonPressed(true);
  //   if (invitationId) {
  //     setIsResendRequestInProgress(true);
  //     const response = await resendTrialPlanInvitation(invitationId);
  //     if (response.success) {
  //       setHasSucceeded(response.success);
  //     }
  //     setIsResendRequestInProgress(false);
  //   }
  // };

  // const resentMessage = isResendRequestInProgress
  //   ? 'Resending email verification...'
  //   : hasSucceeded
  //   ? 'Email verification resent.'
  //   : 'There was a problem sending your invitation.';

  return (
    <Box>
      <StyledHeader>Thank you for requesting a trial.</StyledHeader>
      {invitationId ? (
        <Box>
          <StyledParagraph>
            We’ve sent a confirmation link to <StyledEmail>{email}</StyledEmail>
            . Please check your email to start adding team members.
          </StyledParagraph>
          {/* <StyledParagraph>Didn&lsquo;t get the email?</StyledParagraph>
          <StyledFillButton onClick={resendEmail} disabled={buttonPressed}>
            Resend Verification Email
          </StyledFillButton>
          {buttonPressed && (
            <StyledResent
              aria-live="polite"
              hasErrored={!isResendRequestInProgress && !hasSucceeded}
            >
              {resentMessage}
            </StyledResent>
          )} */}
          <StyledHelpDetails>
            Need Help?{' '}
            <StyledAnchor href="mailto:enterprise@codecademy.com">
              Contact Sales.
            </StyledAnchor>
          </StyledHelpDetails>
        </Box>
      ) : (
        <StyledParagraph>
          An account manager will be in touch with you within 24 hours. If you
          have any immediate questions please email{' '}
          <StyledEmail>
            <Anchor href="mailto:sales@codecademy.com">
              sales@codecademy.com
            </Anchor>
          </StyledEmail>
        </StyledParagraph>
      )}
    </Box>
  );
};
