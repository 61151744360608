import styled from '@emotion/styled';

export const FormHeader = styled.h1`
  margin: 0;

  color: currentColor;
  font-size: ${({ theme }) => theme.fontSize[26]};
  line-height: 1.22;

  ${({ theme }) => theme.breakpoints.sm} {
    font-size: ${({ theme }) => theme.fontSize[44]};
  }
`;

export const FormDetails = styled.p`
  font-size: ${({ theme }) => theme.fontSize[18]};
  line-height: 1.44;
  margin-bottom: 24px;
`;

export const StyledFormError = styled.b`
  align-items: center;
  grid-column-end: span 12;
  justify-content: flex-start;
  height: 2em;

  color: ${({ theme }) => theme.colors.red};
  font-size: ${({ theme }) => theme.fontSize[14]};
  font-weight: ${({ theme }) => theme.fontWeight.base};
  line-height: 1;
`;
