import { Box, FlexBox } from '@codecademy/gamut';
import * as React from 'react';

import { CTA } from './CTA';
import { Description } from './Description';
import { Title } from './Title';
import { CtaProps, LandingPageBaseProps } from './types';

export const PagePrefooter: React.FC<
  LandingPageBaseProps & { additionalCtas?: CtaProps[] }
> = ({ title, desc, cta, onAnchorClick, testId, additionalCtas }) => {
  const SectionTitle = title && <Title>{title}</Title>;
  const Desc = desc && (
    <Description text={desc} onAnchorClick={onAnchorClick} />
  );

  const hasAdditionalCtas = additionalCtas && additionalCtas.length > 0;

  return cta ? (
    <FlexBox
      data-testid={testId}
      flexDirection={{ _: 'column', sm: 'row' }}
      justifyContent="space-between"
      alignItems={{ sm: 'center' }}
    >
      <Box flex={1}>
        {SectionTitle}
        {Desc}
      </Box>
      <FlexBox
        mt={{ _: 32, sm: 0 }}
        ml={{ sm: 32 }}
        gap={16}
        flexDirection={{
          _: 'column',
          md: 'row',
        }}
        maxWidth={{ _: '100%', sm: 'max-content' }}
      >
        <CTA
          key={cta.text}
          href={cta.href}
          onClick={cta.onClick}
          buttonType={hasAdditionalCtas ? 'fill' : cta.buttonType}
        >
          {cta.text}
        </CTA>
        {hasAdditionalCtas
          ? additionalCtas.map((cta) => {
              if (!cta) {
                return null;
              }

              return (
                <CTA
                  key={cta.text}
                  href={cta.href}
                  onClick={cta.onClick}
                  buttonType="fill"
                >
                  {cta.text}
                </CTA>
              );
            })
          : null}
      </FlexBox>
    </FlexBox>
  ) : (
    <div data-testid={testId}>
      {SectionTitle}
      {Desc}
    </div>
  );
};
