import { cfAccessHeaders } from '@mono/data/cf-access-headers';
import { fetchUser } from '@mono/data/user';
import { CaptchaResponse } from '@mono/ui/captcha';
import Cookies from 'universal-cookie';

type RecaptchaVersion = 'v2' | 'v3';

interface TrialFormFields {
  company: string;
  email: string;
  source: string;
  firstname: string;
  lastname: string;
  phoneNumber: string;
  desiredSeats?: number;
  jobTitle?: string;
}

export interface OnTrialInvitationSubmit extends TrialFormFields {
  recaptchaVersion: RecaptchaVersion;
  recaptchaToken: string;
}

export interface OnTrialError {
  description: string;
}

export interface OnTrialInvitationResponse {
  message?: string;
  invitationId?: string;
  planId?: string;
  errors?: OnTrialError[];
}

interface CreateBusinessTrialPayload extends TrialFormFields {
  captcha_version: RecaptchaVersion;
  captcha_token: string;
}

export const createTrialBusinessPlanUrl = `/business/trial`;

export const onTrialInvitationSubmit = async ({
  recaptchaVersion,
  recaptchaToken,
  company,
  email,
  desiredSeats,
  jobTitle,
  source,
  firstname,
  lastname,
  phoneNumber,
}: OnTrialInvitationSubmit): Promise<
  CaptchaResponse<OnTrialInvitationResponse>
> => {
  const payload = {
    company,
    email,
    desiredSeats,
    jobTitle,
    source,
    firstname,
    lastname,
    phoneNumber,
    captcha_version: recaptchaVersion,
    captcha_token: recaptchaToken,
  };
  const response = await createBusinessTrial(payload);

  if (!response.errors) {
    return {
      allowed: true,
      result: {
        message: 'Plan created successfully!',
        invitationId: response.id,
        planId: response.business_paid_plan_id,
      },
    };
  }

  return {
    allowed: true,
    result: {
      errors: response.errors,
    },
  };
};

export const createBusinessTrial = async (
  payload: CreateBusinessTrialPayload
) => {
  const cookies = new Cookies();
  const user = await fetchUser();

  const res = await fetch(
    `${process.env.NEXT_PUBLIC_MONOLITH}${createTrialBusinessPlanUrl}`,
    {
      method: 'POST',
      headers: {
        ...cfAccessHeaders,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Credentials: 'include',
        'X-Auth-Token': user?.auth_token || '',
        'X-CSRF-Token': cookies.get('CSRF-TOKEN'),
      },
      body: JSON.stringify({ ...payload }),
    }
  );

  const data = await res.json();

  return data;
};

export const FORBIDDEN_EMAIL_DOMAINS = [
  `@gmail.`,
  `@yahoo.`,
  `@aol.`,
  `@hotmail.`,
  `@protonmail.`,
  `@yopmail.`,
];

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;

export const FORM_FIELDS = {
  firstname: {
    type: 'text',
    label: 'First Name',
    customError: 'Please enter your first name',
    validate: (value: string) => Boolean(value.length),
  },
  lastname: {
    type: 'text',
    label: 'Last Name',
    customError: 'Please enter your last name',
    validate: (value: string) => Boolean(value.length),
  },
  company: {
    type: 'text',
    label: 'Company Name',
    customError: 'Please enter your company name',
    validate: (value: string) => Boolean(value.length),
  },
  email: {
    type: 'email',
    label: 'Company Email Address',
    customError: 'Please enter a valid email address.',
    validate: (value: string) =>
      Boolean(value.length) &&
      EMAIL_REGEX.test(value) &&
      !FORBIDDEN_EMAIL_DOMAINS.some((domain) =>
        value.toLowerCase().includes(domain)
      ),
  },
  phoneNumber: {
    type: 'tel',
    label: 'Phone Number',
  },
};
