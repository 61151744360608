import { Box, FlexBox, InfoTip, Markdown, Text } from '@codecademy/gamut';

export const FeatureTitle: React.FC<{
  title: string;
  description?: string;
  as?: React.ComponentProps<typeof Text>['as'];
}> = ({ title, description, as }) => (
  <FlexBox
    alignItems="flex-start"
    gap={8}
    justifyContent={{ _: 'space-between', sm: 'flex-start' }}
  >
    <Text fontWeight={700} fontSize={16} as={as}>
      {title}
    </Text>
    {description && (
      <>
        <Box display={{ sm: 'none' }} position="relative" top={-3}>
          <InfoTip
            emphasis="high"
            alignment="bottom-left"
            info={<Markdown text={description} />}
            placement="floating"
          />
        </Box>
        <Box display={{ _: 'none', sm: 'block' }} position="relative" top={-2}>
          <InfoTip
            emphasis="high"
            alignment="bottom-right"
            info={<Markdown text={description} />}
            placement="floating"
          />
        </Box>
      </>
    )}
  </FlexBox>
);
