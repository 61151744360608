import { Currency } from '@mono/data/codegen/monolith';
import { useUser } from '@mono/data/user';

import { AllCurrency, CFBCurrency } from '~/typings/payment';

export interface CurrencyDetails {
  currency: AllCurrency;
  price: number;
}

export const CFBCurrencyList: Record<string, CurrencyDetails> = {
  US: { currency: Currency.Usd, price: 299 },
  IN: { currency: Currency.Inr, price: 24338 },
  AU: { currency: Currency.Aud, price: 443 },
  CA: { currency: Currency.Cad, price: 398 },
  GB: { currency: Currency.Gbp, price: 250 },
  EU: { currency: Currency.Eur, price: 287 },
  SE: { currency: Currency.Sek, price: 3133 },
  NO: { currency: Currency.Nok, price: 2988 },
  PL: { currency: Currency.Pln, price: 1351 },
  DK: { currency: CFBCurrency.Dkk, price: 2138 },
  BR: { currency: CFBCurrency.Brl, price: 1614 },
  BG: { currency: CFBCurrency.Bgn, price: 562 },
  CH: { currency: CFBCurrency.Chf, price: 282 },
  CZ: { currency: CFBCurrency.Czk, price: 7006 },
  HR: { currency: CFBCurrency.Hrk, price: 2169 },
  HU: { currency: CFBCurrency.Huf, price: 117810 },
  MX: { currency: CFBCurrency.Mxn, price: 5778 },
  RO: { currency: CFBCurrency.Ron, price: 1415 },
};

export const ConsumerProCurrencyList: Record<string, CurrencyDetails> = {
  US: { currency: Currency.Usd, price: 359 },
  IN: { currency: Currency.Inr, price: 29424 },
  AU: { currency: Currency.Aud, price: 529 },
  CA: { currency: Currency.Cad, price: 473 },
  GB: { currency: Currency.Gbp, price: 281 },
  EU: { currency: Currency.Eur, price: 326 },
  SE: { currency: Currency.Sek, price: 3802 },
  NO: { currency: Currency.Nok, price: 3644 },
  PL: { currency: Currency.Pln, price: 1444 },
  DK: { currency: CFBCurrency.Dkk, price: 2431 },
  BR: { currency: CFBCurrency.Brl, price: 1758 },
  BG: { currency: CFBCurrency.Bgn, price: 638 },
  CH: { currency: CFBCurrency.Chf, price: 313 },
  CZ: { currency: CFBCurrency.Czk, price: 7908 },
  HR: { currency: CFBCurrency.Hrk, price: 2458 },
  HU: { currency: CFBCurrency.Huf, price: 126639 },
  MX: { currency: CFBCurrency.Mxn, price: 6117 },
  RO: { currency: CFBCurrency.Ron, price: 1615 },
};

const euCountries = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
];

export const useLocalizedCurrency = (planId: string) => {
  const user = useUser();
  const geoCountry = user?.location?.geo_country || 'US';
  const currencyList =
    planId === 'teams' ? CFBCurrencyList : ConsumerProCurrencyList;

  if (euCountries.includes(geoCountry)) {
    return currencyList.EU;
  }

  // ensure that we only return a country that we support
  if (currencyList[geoCountry]) {
    return currencyList[geoCountry];
  }

  return currencyList.US;
};

export const useUserGeoLocation = () => {
  const user = useUser();
  return user?.location?.geo_country || 'US';
};
