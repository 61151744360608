import { Box } from '@codecademy/gamut';
import { FeatureFlag } from '@mono/data/feature-flags';

import { useFeatureFlag } from '~/libs/FeatureFlags/featureFlags';

import { PricingTableDesktop } from './PricingTableDesktop';
import { PricingTableLegacy } from './PricingTableLegacy';
import { PricingTableMobile } from './PricingTableMobile';
import { BusinessPlan, PlanFeature } from './types';

export type PricingTableProps = {
  title: string | null;
  plans: BusinessPlan[];
  features: PlanFeature[];
  testId: string;
};

export const PricingTable: React.FC<PricingTableProps> = ({
  title,
  testId,
  features,
  plans,
}) => {
  const isNewTeamsLP = useFeatureFlag(FeatureFlag.CYCLE_E_TEAMS_REVAMP);

  return isNewTeamsLP ? (
    <>
      <Box display={{ _: 'none', md: 'block' }}>
        <PricingTableDesktop />
      </Box>
      <Box display={{ md: 'none' }}>
        <PricingTableMobile />
      </Box>
    </>
  ) : (
    <PricingTableLegacy
      title={title}
      features={features}
      plans={plans}
      testId={testId}
    />
  );
};
