import { Card, FillButton, FlexBox, Text, TextButton } from '@codecademy/gamut';
import React from 'react';

import { trackUserClick } from '~/libs/tracking';
import { getCurrencySymbol } from '~/typings/payment';

import { BusinessPlan } from '../types';
import { useLocalizedCurrency } from './pricingList';

export type PricingCardProps = BusinessPlan;

export const PricingCard: React.FC<PricingCardProps> = ({
  planId,
  title,
  description,
  cta,
  isRecommended,
}) => {
  const currencyDetails = useLocalizedCurrency(planId);
  const Button = isRecommended ? FillButton : TextButton;
  const trackingContext =
    planId === 'teams'
      ? 'teams_card_trial_start'
      : 'teams_plus_card_request_demo';
  const trackingTarget =
    planId === 'teams' ? 'business_trial_sign_up' : 'pro_trial_cta';
  return (
    <Card
      variant="white"
      pb={24}
      border="none"
      borderWidthTop={4}
      borderStyleTop="solid"
      borderColorTop={isRecommended ? 'hyper' : 'navy'}
    >
      <FlexBox justifyContent="space-between" pt={32}>
        <Text variant="title-sm" as="h3" maxWidth="173px">
          {title}
        </Text>
        {isRecommended && (
          <Text
            bg="yellow"
            fontFamily="accent"
            height="100%"
            px={8}
            py={4}
            variant="p-small"
          >
            Recommended
          </Text>
        )}
      </FlexBox>

      <FlexBox alignItems="flex-end" py={16}>
        {planId === 'teams' && (
          <>
            <Text
              variant="title-xl"
              fontSize={{ _: 44, lg: 34, xl: 44 }}
              fontFamily="accent"
              mr={8}
            >
              {getCurrencySymbol(currencyDetails.currency)}
              {currencyDetails.price}
            </Text>
            <Text pb={8} maxWidth="174px">
              per user, yearly
            </Text>
          </>
        )}
        {planId !== 'teams' && (
          <Text
            variant="title-xl"
            fontSize={{ _: 34, md: 26, lg: 34 }}
            fontFamily="accent"
            mr={8}
            pt={8}
          >
            Custom Price
          </Text>
        )}
      </FlexBox>

      {description && (
        <Text variant="p-large" fontSize={18} maxWidth={{ md: 235, lg: 283 }}>
          {description}
        </Text>
      )}

      <Button
        my={32}
        onClick={() =>
          trackUserClick({
            target: trackingTarget,
            context: `business_landing_page_v2_${trackingContext}`,
            page_name: 'business_landing_v2',
            name: 'pricing_card_cta',
          })
        }
        href={cta.href}
        mr={16}
        aria-label={cta.title}
      >
        {cta.title}
      </Button>
    </Card>
  );
};
